@primary-color: white;
@blue-primary: #5E5DF0;
@blue-secundary: #CDD9ED;
@transitionDuration: 100;

@red: #f16165;

@import (css) "https://fonts.googleapis.com/css?family=Dosis";
@import (css) "https://fonts.googleapis.com/css2?family=Georama:wght@400;700&display=swap";
@import (css) "css/normalize.css";
@import (less) "css/print.less";

html {
  font-family: 'Lucida Sans', sans-serif;
	font-size: 1em;
	line-height: 1.4;

	body {
		padding: 0;
		margin: 0;
		background-image: url(img/casal_com_as_contas_em_dia.jpg);
		background-position: 100%;
		background-size: cover;
		background-repeat: no-repeat;

		a {
			color: #FFF;
			text-decoration: none;
		}

		header {
			float: left;
			padding: 25px;
		}
		
		div.overlay {
			background: rgba(65, 105, 225, 0.8)
		}
		
		nav ul {
			float: right;
			li {
				padding: 25px;
				display: inline;
				border-bottom: 3px solid @blue-primary;
				&:hover {
					background: rgba(65, 105, 225, 0.5)
				}
				a {
					color: #FFF;
					font-weight: bold;
					text-decoration: none;
					font-family: 'Lucida Sans', sans-serif;
					font-size: 16px;
				}
			}
		}
		
		.simulator-container {
			clear: both;
			display: flex;
			flex: 100%;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			padding: 20px;
			height: 780px;
		
			article {
				flex: 100%;
				header {
					h1 {
						font-family: 'Georama', sans-serif;
						font-size: 48px;
						color: #FFF;
						margin-bottom: 0;
					}
					h2 {
						font-family: 'Lucida Sans', sans-serif;
						font-size: 16px;
						color: @blue-secundary;
					}
				}
			}
			
			aside .simulator {
				background-color: #FFF;
				border-radius: 25px;
				padding: 25px;
				flex: 0.3;
				div {
					margin: 20px 0;
				
					label { 
						input[type=text], input[type=email] {
							font-size: 18px;
							padding: 10px;
							border-radius: 5px;
							color: #45336b;
							border: 1px solid @blue-secundary;
							width: 90%;
		
							&:focus {
								border: 1;
								outline: none;
							}
						}
						select {
							padding: 10px;
							font-size: 18px;
							width: 97%;
							border-radius: 5px;
							color: #45336b;
							border: 1px solid @blue-secundary;
						}
						span {
							display: block;
							margin-bottom: 5px;
							font-family: 'Lucida Sans', sans-serif;
							font-size: 18px;
						}
					}
				}
			}
		}
		
		.button {
			padding: 15px;
			background: #5E5DF0;
			border-radius: 999px;
			box-shadow: #5E5DF0 0 10px 20px -10px;
			box-sizing: border-box;
			color: #FFFFFF;
			cursor: pointer;
			font-size: 18px;
			font-weight: 700;
			line-height: 32px;
			opacity: 1;
			outline: 0 solid transparent;
			padding: 8px 18px;
			user-select: none;
			-webkit-user-select: none;
			touch-action: manipulation;
			width: fit-content;
			word-break: break-word;
			border: 0;
			width: 100%;
		}

		.error {
			border: @red;
			color: @red;
			.elError {
				margin-top: 5px;
				font-size: 12px !important;
			}
		}
		footer {
			color: #FFF;
			section {
				flex: 1;
				flex-direction: column;
				padding: 15px 25px;
				background-color: #3b3b3b;
				ul {
					margin: 0;
					list-style-type: none;
				}
				li.title {
					font-weight: bold;
					margin-bottom: 10px;
				}
			}
			section.copyright {
				background-color: #000;
				flex: 1;
				flex-direction: column;
				p {
					flex: 0.5;
				}
			}
		}
	}
}
