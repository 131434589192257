@media print {
	* {
		background: #fff !important;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
		&::before {
			background: #fff !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
		&::after {
			background: #fff !important;
			color: #000 !important;
			box-shadow: none !important;
			text-shadow: none !important;
		}
	}
	a {
		text-decoration: underline;
		&:visited {
			text-decoration: underline;
		}
	}
	a[href] {
		&::after {
			content: " (" attr(href) ")";
		}
	}
	abbr[title] {
		&::after {
			content: " (" attr(title) ")";
		}
	}
	a[href^="#"] {
		&::after {
			content: "";
		}
	}
	a[href^="javascript:"] {
		&::after {
			content: "";
		}
	}
	pre {
		white-space: pre-wrap !important;
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr {
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
	}
	p {
		orphans: 3;
		widows: 3;
	}
	h2 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
	h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}
}